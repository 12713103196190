// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/bonhote/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/bonhote/531c5a28-5575-4f58-96d4-a80f7b702d7b.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable no-empty-source */\n/* stylelint-disable no-invalid-position-at-import-rule */\n/* stylelint-disable no-duplicate-at-import-rules */\n/* stylelint-disable scss/dollar-variable-empty-line-before */\n@font-face {\n  font-family: \"Helvetica Neue Lt\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");\n  font-weight: normal;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Helvetica Neue Lt\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");\n  font-weight: bold;\n  font-style: normal;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors-primary": "#b20f21",
	"colors-success": "#28c76f",
	"colors-danger": "#ea5455",
	"colors-warning": "#ffba00",
	"colors-dark": "#1e1e1e",
	"colors-grey-dark": "#56595c",
	"colors-grey": "#b8c2cc",
	"colors-grey-light": "#dae1e7",
	"colors-white": "#fff",
	"colors-black": "#22292f",
	"colors-invisible": "rgba(0, 0, 0, 0)",
	"colors-benchmark": "#bfb8af",
	"colors-benchmark-dark": "#5f5f5f",
	"colors-live": "#c7650a",
	"misc-border-radius-px": "5px",
	"typography-font-family-sans-serif": "\"Helvetica Neue Lt\", sans-serif"
};
export default ___CSS_LOADER_EXPORT___;
